<template>
  <div class="w100 h100 share">
    <img src="@/assets/img/icon989.png" class="w100" />
    <img src="@/assets/img/icon987.png" class="w90 block mxauto icon987" />
    <img src="@/assets/img/icon991.png" class="absolute bottom0 left0 w100" />
    <div class="absolute bottom10 oh bottom-wrapper">
      <div class="w45 fl row">
        <!-- ios二维码 -->
        <div class="ios-wecode">
          <img src="@/assets/img/icon992-ios.png" class="w100 block mxauto" />
          <p class="cf tc">Ios</p>
        </div>
        <!-- 安卓二维码 -->
        <div class="android-wecode ml20">
          <img
            src="@/assets/img/icon993-android.png"
            class="w100 block mxauto"
          />
          <p class="cf tc">Android</p>
        </div>
      </div>
      <!-- 下载按钮 -->
      <div class="w55 fl">
        <!-- 用户端 -->
        <a :href="downloadUrl">
          <img src="@/assets/img/icon988.png" class="icon988" />
        </a>
        <p class="cf">下载舒昕逸家APP</p>
        <p class="cf font12">获取更多好礼,随时随地签到任务</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "recommend",
  components: {},
  data() {
    return {
      downloadUrl: "",
      defaultUrl: {
        ios: "https://apps.apple.com/cn/app/id1533676322",
        andronid:
          "https://a.app.qq.com/o/simple.jsp?pkgname=com.chumo.app.user",
      },
    };
  },
  methods: {},
  mounted() {
    let brower = this.$utils.judgeBrower();
    if (brower == "ios") {
      this.downloadUrl = this.defaultUrl.ios;
    } else {
      this.downloadUrl = this.defaultUrl.andronid;
    }
  },
};
</script>

<style lang="less" scoped>
.share {
  background: url(../../assets/img/icon990.png) repeat center;
}
.bottom-wrapper {
  left: 5%;
  .ios-wecode {
    width: 36%;
  }
  .android-wecode {
    width: 36%;
  }
  .icon988 {
    width: 72%;
  }
}
@media (max-height: 700px) {
  .icon987 {
    margin-top: 20px;
  }
}
@media (min-height: 700px) {
  .icon987 {
    margin-top: 40px;
  }
}
</style>
